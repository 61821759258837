import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const PatentBasics = () => {
    const seo = {
        metaDesc: 'Patent Basics - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Patent Basics - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <h1>1. Patent Basics</h1>
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                                        <li>A patent is a form of intellectual property that applies to inventions.</li>
                                        <li>Patents are issued by the United States Patent and Trademark Office (USPTO).</li>
                                        <li>They provide the owner with the right to shut out others from making, using, selling, or offering to sell the inventions described in the patent.</li>
                                        <li>Depending on the type of patent, the exclusive right lasts for 15-20 years.</li>
                                        <li>To be patentable, three elements are necessary of any invention: usefulness (for the case of utility and plant patents), novelty, and non-obviousness.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <p class="f-16"><strong>Table of Contents</strong></p>
                        <p class="f-16"><strong>1.1&nbsp;&nbsp;&nbsp; <a href="#legalauthority" class="d-clr">What is the legal authority behind patents?</a></strong></p>
                        <p class="f-16"><strong>1.2&nbsp;&nbsp;&nbsp; <a href="#governmentagency" class="d-clr">What government agency is in charge of administering patents?</a></strong></p>
                        <p class="f-16"><strong>1.3&nbsp;&nbsp;&nbsp; <a href="#patentadvantages" class="d-clr">What are the advantages of owning a patent?</a></strong></p>
                        <p class="f-16"><strong>1.4&nbsp;&nbsp;&nbsp; <a href="#patentingfacrors" class="d-clr">Factors to consider before Patenting:</a></strong></p>
                        <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.4.1&nbsp; <a href="#commercializable" class="d-clr">Is the invention commercializable?</a></strong></p>
                        <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.4.2&nbsp; <a href="#invention" class="d-clr">Did I invent the invention?</a></strong></p>
                        <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.4.3&nbsp; <a href="#own" class="d-clr">Do I own the invention?</a></strong></p>
                        <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.4.4&nbsp; <a href="#patentable" class="d-clr">Is the invention patentable?</a></strong></p>
                        <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.4.5&nbsp; <a href="#class" class="d-clr">Does it fit into a patent “class”?</a></strong></p>
                        <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.4.6&nbsp; <a href="#novel" class="d-clr">Is it novel?</a></strong></p>
                        <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><strong>1.4.7&nbsp; <a href="#non-obvious" class="d-clr">Is it non-obvious?</a></strong></p>
                    </div>
                    <img src="../assets/img/banner/patented-stamp-shows-trademark-patent-or-registered_z1DGWGPO-e1602424972610.jpg" width="100%" alt="" />
                    <h1 id="legalauthority">1.1 What is the legal authority behind patents?</h1>
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                                        <li>The Patent and Copyright Clause of the United States Constitution was passed on April 10, 1790, titled “An Act to promote the progress of useful Arts.”</li>
                                        <li>The basic aim of patent law is to balance the interests of inventors and those of the public.</li>
                                        <li>The inventors are rewarded with exclusive rights on their invention for providing useful creation progress to the public.</li>
                                        <li>Patents provide insights into ongoing technological developments and help to avoid parallel redundant developments.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="f-16">
                        The Constitutional basis for the federal patent and copyright systems is based on the Constitution of the United States Article 1, Section 8, clause 8, which states:  “Congress shall have power . . . to promote the progress of science and useful arts by securing for limited times to authors and inventors the exclusive right to their respective writings and discoveries.”
                        <br /><br />
                        A U.S. patent grants the inventor “the right to exclude others from making, using, offering for sale, or selling” the invention in the United States or “importing” it into the United States. It facilitates innovation by rewarding individuals for their creativity. As Abraham Lincoln once put it, “The Patent System added the fuel of interest to the fire of genius.”
                    </p>
                    <h2 id="governmentagency">1.2 What government agency is in charge of administering patents?</h2>
                    <img src="../assets/img/banner/thervo-uspto-trademark-registration-filing-e1602425150319.jpg" width="100%" alt="" />
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                                        <li>The <strong>United States Patent and Trademark Office</strong>&nbsp;(<strong>USPTO</strong>) is an agency in the U.S. Department of Commerce that issues patents to inventors and businesses for their inventions and trademark registration.</li>
                                        <li>They examine applications to determine validity.</li>
                                        <li>Also, they keep records and publishes issued patents and approved trademark.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="f-16">
                        The U.S. Patent and Trademark Office (USPTO), a branch of the Department of Commerce, administers patent and trademark laws related to the granting of patents for utility inventions, designs, and plants, and the issuing of trademark registrations. In particular, the USPTO specifically examines applications for patents to determine if the applicants are entitled to patents and grants the patents if they are.
                        <br /><br />
                        The USPTO also publishes issued patents; approves trademark registrations and various publications concerning patents and trademarks; records assignments of patents and trademarks; and maintains search rooms and a national network of Patent and Trademark Depository Libraries.  These libraries are used by the public to study issued patents, registered trademarks, and pending applications and records that are related to both patents and trademarks. It also supplies copies of records and other papers.
                    </p>
                    <h2 id="patentadvantage">1.3 What are the advantages of owning a patent?</h2>
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ol style={{ paddingInlineStart: 40 + 'px' }}>
                                        <li>You can license it</li>
                                        <li>You can sell it</li>
                                        <li>You can stop others from using it without your permission</li>
                                        <li>You can make and use the invention</li>
                                    </ol>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="f-16">
                        Inventors are recognized annually by many organizations as being important to modern life. As previously stated, a patent protects your invention and associated investment. Here are some examples of benefits:
                    </p>
                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}><li>As the patent owner, you can preclude others from making, using, or selling your invention throughout the United States.</li><li>As an industry matures and gains more value, a key patent can also increase in value over time.</li><li>A patent can add significant value to businesses, including start-ups because it is considered a valuable asset by investors, venture capitalists, banks, and potential purchasers of your business.</li><li>There are many ways to monetize your patented invention. You may sell or license your patent to one or more parties for royalties, or you may choose to be the exclusive manufacturer of your invention.</li></ul>
                    <h2 id="patentingfactors" class="mt-3">1.4 Factors to consider before Patenting:</h2>
                    <h3 id="commercializable">1.4.1 Is the invention commercializable?</h3>
                    <p class="f-16">
                        Look at the cost of your invention, competitors’ products, the invention’s ease of use, and consumer demand.
                    </p>
                    <h3 id="invention">1.4.2 Did I invent the invention?</h3>
                    <p class="f-16">
                        You can only obtain a patent if you personally invented something. In the US, only the inventor(s) can apply for the patent. A co-inventor is someone who contributed to at least one claim of the application.
                    </p>
                    <h3 id="own">1.4.3 Do I own the invention?</h3>
                    <p class="f-16">
                        You may not own the invention. This may be the case if your employer owns the rights to the invention, i.e. if you have contractually given up rights to the invention or if you were hired specifically to invent, then your employer has rights to the invention
                    </p>
                    <h3 id="patentable">1.4.4 Is the invention patentable?</h3>
                    <p class="f-16">
                        A patentable invention must be:<br /> • Patentable subject matter<br /> • Novelty<br /> • Non-obviousness<br /> • Description that is adequately described or enabled<br /> • Claimed in clear and definite terms
                    </p>
                    <p class="f-16">
                        A patent is only granted for useful inventions. Though most inventions are useful, the USPTO has found the following to be ineligible for patents:<br /> ● Ornamental, without utility. You may want to consider a design patent application if your invention has visual ornamental characteristics embodied in or applied to an article of manufacture that is not functionally useful;<br /> ● Unsafe drugs;<br /> ● Non-operable inventions;<br /> ● Inventions only with illegal uses; and<br /> ● Theoretical phenomenon.
                    </p>
                    <h3 id="class">1.4.5 Does it fit into a patent “class”?</h3>
                    <img src="../assets/img/banner/classified-file-shows-private-documents-or-papers_zJ85zVPd-e1602425292218.jpg" width="100%" alt="" />
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                                        <li>Patent examiners typically classify an inventor’s application for a grant of patent into a number of classes, depending on that invention’s components and functions.</li>
                                        <li>To conduct a thorough and comprehensive patent search, you should query the database by class.</li>
                                        <li>Classification might bring together similar devices and concepts, even when different terms have been used to describe the inventions.</li>
                                        <li>For a patent to issue must fall under the five given classes:</li>
                                    </ul>
                                    <p class="f-16">i.&nbsp; Processes and methods</p>
                                    <p class="f-16">ii.&nbsp; Machines</p>
                                    <p class="f-16">iii.&nbsp; Articles of manufacture</p>
                                    <p class="f-16">iv.&nbsp; Composition matter</p>
                                    <p class="f-16">v.&nbsp; &nbsp;Improvements</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="f-16">
                        The U.S. Supreme Court said that anything man-made falls into these “classes,” but anything natural or abstract will not.  The “classes” are broad, so an invention is likely to fit into one of these categories.  In fact, it might fit into a couple.  Ultimately, for a patent to issue, an invention must fall into at least one of them.  The following are the five “classes.”
                    </p>
                    <h4>1.4.5.1 Processes and methods</h4>
                    <p class="f-16">
                        These are steps for doing or making something. They can be steps that change something physically or even manual steps, like how you type on a keyboard. They cannot, however, consist of only mental steps.
                    </p>
                    <h4>1.4.5.2 Machines</h4>
                    <p class="f-16">
                        Machines are devices or things that interact and, as a result, accomplish something. It’s related to processes, but the focus here is on the actual machine, not the methods that the machine employs. Machines can include traditional notions of what is a machine, such as a sewing machine. It also covers software depending on how you write the patent application.
                    </p>
                    <h4>1.4.5.3 Articles of manufacture
                    </h4>
                    <p class="f-16">
                        This includes what results from manufacturing or what’s made by humans. It’s an invention that accomplishes something without the interaction of moveable parts or objects with incidental moveable parts. For example, knives, erasers, or books are articles of manufacture. Usually, naturally occurring things do not fit into this category, but there are some exceptions, e.g., a novel, non-obvious use of a natural thing. Also, there may be some overlap between articles of manufacture and machines or compositions of matter.
                    </p>
                    <h4>1.4.5.4 Compositions of matter
                    </h4>
                    <p class="f-16">
                        Chemicals or other material combinations that are produced as a result of chemicals are compositions of matter. This includes glues, plastics, and gasoline. Natural items are not considered compositions of matter, but, for example, their derivative chemicals or extracts in the purified form are.
                        <br />
                        Compositions of the matter often overlap with articles of manufacture. However, compositions are primarily concerned with the chemical building blocks rather than the shape of the article.
                    </p>
                    <h4>1.4.5.5 Improvements
                    </h4>
                    <p class="f-16">
                        Improvements are novel uses for an existing invention. Under this category, you may need a license from the original patent owner if the underlying invention is still patent protected.
                    </p>
                    <h3 id="novel">1.4.6 Is it novel?</h3>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                                        <li>
                                            Novelty is determined based&nbsp; on:
                                            <ul>
                                                <li>&nbsp;Ascertaining the prior art</li>
                                                <li>Applying factors to determine non-obviousness</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="f-16">
                        Patented inventions must be different from existing knowledge or previous inventions, otherwise known as prior art. That means the new invention should be physically or operationally unique in at least one way from existing solutions (prior art) on the date you filed the patent application.  Novelty may arise when the invention incorporates a new feature, uses an old feature in a new way, or applies a new combination of old features.
                    </p>
                    <h4 id="priorart">1.4.6.1 Prior art</h4>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                                        <li>Prior art is documentary evidence that can be used to determine novelty or non-obviousness of the claimed subject matter for a patent.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="f-16">
                        Prior art is any evidence used to determine if your invention is known before your filing date.  It can be a video, a product demo, a whitepaper, or a brochure describing concepts similar to your invention.  For example prior art can be:
                    </p>
                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}>
                        <li>
                            Anything in public use or sold in the United States for more than a year prior to the patent application’s filing date;</li><li>Anything publicly is known or used by others in this country before the filing date;</li><li>Anything made or built in this country by another before the invention date;</li><li>Prior patents filed more than a year before the patent’s filing date;</li><li>Prior publications dated more than a year before the patent’s filing date or any time before the date of the invention.
                        </li>
                    </ul>
                    <p class="f-16 mt-3">If the invention was publicly used or described or shown to the public as such acts could be used as prior art against the applicant’s own patent application.&nbsp; In the US, there is a grace period of one year from such acts, but it is still inadvisable for an inventor to publish before filing.&nbsp; Since ProvisionalBuilder® software reduces the cost for patenting so much, you should consider filing ASAP.</p>
                    <p class="f-16">In the United States, a patent application must be filed within one year after an inventor sells his/her idea, offers it for sale, or publicly or commercially describes or uses it.&nbsp; Thus, you should file the application prior to doing this, and if no later than one year from doing so. Otherwise, the patent can be barred through your own sales and commercialization activities.</p>
                    <h3 id="non-obvious">1.4.7 Is it non-obvious?</h3>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    An invention is considered non-obvious if someone skilled in the field of the invention would view it as an unexpected or astonishing development.
                                    <p></p>
                                    <p class="f-16">Factors in determining obviousness include:</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; invention has commercial success;</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; invention solves a non-obvious problem;</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; invention subtracts a piece of hardware that was included in the prior art;</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; invention modifies the prior art in a new way;</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; industry needs the invention;</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; others have tried to come up with this invention but failed;</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; other inventors said this invention was impossible;</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; others have copied this invention; or</p>
                                    <p class="f-16">●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; others in the field have praised the invention.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="f-16">
                        This is the highest bar to patent ownership.  If something is obvious, then it isn’t patentable.  If it is surprising and unexpected, then it usually is non-obvious and hence, may be patentable.
                    </p>
                    <p class="f-16">Factors in determining obviousness include:</p>
                    <ul style={{ listStyle: 'disc', paddingInlineStart: 40 + 'px' }}><li>Invention has commercial success;</li><li>Invention solves a non-obvious problem;</li><li>Invention subtracts a piece of hardware that was included in the prior art;</li><li>Invention modifies the prior art in a new way;</li><li>Industry needs the invention;</li><li>Others have tried to come up with this invention but failed;</li><li>Other inventors said this invention was impossible;</li><li>Others have copied this invention; or</li><li>Others in the field have praised the invention.</li></ul>
                    <p class="f-16">
                        You can read more about the USPTO’s process for determining obviousness at
                        <a href="http://www.uspto.gov/web/offices/pac/mpep/documents/2100_2141.htm" class="d-clr" target="_blank">
                            USPTO.gov
                        </a>
                        <br /><br />
                        Briefly, the focus for the USPTO when making a determination of obviousness is—what a person of ordinary skill in the pertinent art would have known at the time of the invention, and on what such a person would have reasonably expected to have been able to do in light of that knowledge. This is the standard regardless of whether the source of that knowledge and ability was documented prior art, general knowledge in the art, or common sense.
                    </p>
                </div>
            </section>
        </Layout>
    )
};

export default PatentBasics;